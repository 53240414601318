import React from 'react';
import style from './index.module.scss';
import image from '../../assets/images/steps/do_posle.jpeg';

export default function StepsToWrite({ toggleModal }) {
	return (
		<div className={style.steps}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>
						Аппаратный маникюр - это полноценная лечебная процедура
					</h2>
				</div>
				<div className={style.row}>
					<div className={style.col}>
						<div className={style.imgWrapper}>
							<div className={style.imageContainer}>
								<img className={style.img} src={image} alt='' />
							</div>
						</div>
					</div>
					<div className={style.col}>
						<h3 className={style.subtitle}>Что входит в маникюр:</h3>
						<ul className={style.list}>
							<li className={style.item}>
								<div className={style.number}>-</div>Осмотр и консультация
							</li>
							<li className={style.item}>
								<div className={style.number}>-</div>Медицинский маникюр
							</li>
							<li className={style.item}>
								<div className={style.number}>-</div>Бережное восстановление
							</li>
							<li className={style.item}>
								<div className={style.number}>-</div>Назначение рекомендаций по
								уходу
							</li>
							<li className={style.item}>
								<div className={style.number}>-</div>Маникюр делает специалист с
								опытом от 7 лет, что гарантирует высокое качество услуг и
								безопасность здоровья пациента
							</li>
						</ul>
					</div>
				</div>
				<div className={style.button} onClick={toggleModal}>
					Получить консультацию
				</div>
			</div>
		</div>
	);
}
