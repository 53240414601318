/* eslint-disable no-undef */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import InputMask from 'react-input-mask';
import quizData from './helpers/quizData.json';
import { getUnMaskPhone } from '../../utils/maskPhone';
import { getQuizDataParsing } from '../../utils/quizDataParsing.js';

export default function Quiz({ landingData, yandex }) {
	const navigation = useNavigate();
	const [info, setInfo] = React.useState({}); // стейт выбора ответов пользователя
	const [questions] = React.useState(quizData); // стейт вопросов
	const [value, setValue] = React.useState(0); // индекс активного вопроса
	const [isSelected, setIsSelected] = React.useState(''); // стейт для проверки выбран ли ответ
	const [control, setControl] = React.useState(false); // стейт для проверки прохождения всей длины массива вопросов

	// кнопка вперед
	const increment = () => {
		if (value < questions.length - 1 && isSelected) {
			setValue(prev => prev + 1);
			setIsSelected('');
		} else if (isSelected) {
			setControl(true);
		}
	};

	// кнопка назад
	const decrement = () => {
		if (value > 0) {
			setValue(prev => prev - 1);
			setIsSelected('');
		}
	};

	// получаем данные в стейт
	const getInfo = event => {
		setIsSelected(event.target.value);

		setInfo(prev => ({ ...prev, [event.target.name]: event.target.value }));
	};

	//проверка radio button
	const handleChange = event => {
		setIsSelected(event.target.value);
	};

	const getUserPhone = event => {
		event.preventDefault();
		const phone = event.target.phone.value;
		const newPhone = getUnMaskPhone(phone);
		let notes = getQuizDataParsing(info);

		if (phone) {
			let userDataQuiz = {
				name: 'QUIZ',
				phone: newPhone,
				department: landingData.department,
				status: 1,
				notes,
				city: landingData.city,
				email: '',
				url: window.location.href,
			};

			fetch(
				'https://onshorkin.com/i8fd90sd/v09cxvjksdf/xzs9/sda9/service_request/',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify(userDataQuiz),
				}
			)
				.then(response => response.json())
				.then(data => {
					console.log(data);
					ym(yandex, 'reachGoal', 'SubmittingSurveyForm');
					_tmr.push({
						type: 'reachGoal',
						id: 3314675,
						value: 900,
						goal: 'otpravkakviz',
					});
				})
				.catch(e => {
					console.log(e);
					navigation('/error');
				});
			navigation('/sent');
		}
	};

	return (
		<div className={style.quiz}>
			<div className={style.containerForm}>
				<div className={style.inner}>
					<div className={style.quizTitle}>
						<div className={style.title}>
							Ответьте на 5 вопросов и получите бонус!
							<div className={style.line}></div>
						</div>
					</div>
				</div>

				{control ? (
					<form onSubmit={getUserPhone} className={style.step}>
						<div className={style.final}>
							<div className={style.title}>
								Заполните форму и получите результат
								<br /> + БОНУС!
							</div>
							<div className={style.phone}>
								<InputMask
									className={style.input}
									mask='+7 (999) 999-99-99'
									type='tel'
									name='phone'
									id='phone'
									placeholder='Ваш номер телефона'
									required
								/>
							</div>
							<button className={style.button}>Получить результаты</button>
						</div>
					</form>
				) : (
					<>
						<form onChange={getInfo} className={style.slider}>
							<div className={style.step}>
								<div className={style.container}>
									<div className={style.question}>
										{questions[value].question}
									</div>
									<div className={style.remark}>{questions[value].remark}</div>
									{questions[value].answers.map(answer => (
										<div className={style.answer} key={answer.id}>
											<label className={style.label}>
												<input
													className={style.input}
													name={questions[value].question}
													value={answer.answerText}
													type={questions[value].type}
													checked={isSelected === answer.answerText}
													onChange={handleChange}
												/>
												<span className={style.span}>{answer.answerText}</span>
											</label>
										</div>
									))}
								</div>
							</div>
							<div className={style.innerControl}>
								<div className={style.controls}>
									<button
										type='reset'
										className={style.btnPrev}
										onClick={decrement}
									>
										Назад
									</button>
									<div className={style.status}>
										Вопрос
										<span className={style.current}> {value + 1} </span>
										из
										<span className={style.total}> {questions.length} </span>
									</div>
									<button
										type='reset'
										className={isSelected ? style.btnNextActive : style.btnNext}
										onClick={increment}
									>
										Далее
									</button>
								</div>
								<div className={style.progress}>
									<div
										className={style.progressBar}
										style={{ width: `${(value / questions.length) * 100}%` }}
									></div>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</div>
	);
}
