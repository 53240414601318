import problem1 from '../../assets/images/problems/ojog.jpg';
import problem2 from '../../assets/images/problems/onihodistrof.jpg';
import problem3 from '../../assets/images/problems/vrosshiy.jpg';
import problem4 from '../../assets/images/problems/dermatit.jpg';
import problem5 from '../../assets/images/problems/gribok.jpg';
import problem6 from '../../assets/images/problems/rassloenie.jpg';
import problem7 from '../../assets/images/problems/potliv.jpg';
import problem8 from '../../assets/images/problems/krasn.jpg';

export const images = [
	{
		id: 1,
		img: problem1,
		title: 'Ожог ногтей',
		description: `Оказать квалифицированную помощь может исключительно врач-подолог после осмотра пораженной области. Пожалуйста, не откладывайте визит к специалисту и не ищите возможности для самолечения. Пораженная область может стать инфекционным или грибковым очагом. Открытые раны представляют особую угрозу для здоровья!`,
	},
	{
		id: 2,
		img: problem2,
		title: 'Ониходистрофия',
		description: `Это собирательный термин, который используется для обозначения заболеваний ногтевого аппарата, сопровождающихся изменением цвета, формы или структуры ногтей. Они возникают под влиянием разнообразных факторов: травм, витаминодефицитов, хронических дерматозов и др. Самыми яркими проявлениями ониходистрофии являются ломкость ногтя и его отделение от ногтевого ложа.`,
	},
	{
		id: 3,
		img: problem3,
		title: 'Вросший ноготь',
		description: `Врастание ногтевой пластины в боковой край ногтевого валика (чаще всего на большом пальце ноги, чаще внешний край). Это весьма распространённое заболевание. При этом ногтевой валик становится красным и отёчным, начинается воспаление, вызывающее сильную боль. В дальнейшем возможно развитие хронического воспалительного процесса.`,
	},
	{
		id: 4,
		img: problem4,
		title: 'Дерматит',
		description: `Воспаление кожи, развивающееся в месте непосредственного действия на неё внешнего физического или химического фактора. Иногда термин используется в более широком смысле как название любого воспаления кожи (см. напр. атопический дерматит). Для уменьшения путаницы в медицине принят термин «контактный дерматит», а поражения кожи, вызванные иными причинами, относят к токсидермиям.`,
	},
	{
		id: 5,
		img: problem5,
		title: 'Грибок ногтей',
		description: `Онихомикоз (грибок ногтей) — это поражение грибковой инфекцией ногтевой пластины и окружающих её структур: ногтевых валиков, матрикса (ростковая часть ногтя) и ногтевого ложа. Проявляется деформацией и утолщением ногтей, изменением их цвета — ногти становятся белыми или жёлтыми.`,
	},
	{
		id: 6,
		img: problem6,
		title: 'Расслоение ногтей',
		description: `Это нарушение однородной структуры ногтевой пластины, приводящее к ее расщеплению на отдельные листовидные чешуйки. Данное состояние относится к дистрофиям ногтя, при которых изменения формы и структуры ногтевой пластины связано с нарушением клеточного питания ногтя.`,
	},
	{
		id: 7,
		img: problem7,
		title: 'Излишняя потливость ладоней',
		description: `Это нарушение функционирования эккриновых потовых желез, расположенных в коже ладонных поверхностей кистей, которое сопровождается повышением выделения пота.`,
	},
	{
		id: 8,
		img: problem8,
		title: 'Гиперемия',
		description: `Патологическое переполнение кровью сосудов, расположенных на ограниченном участке тела или в каком-либо органе. Может носить временных характер или сопровождать заболевание инфекционного или воспалительного характера, при этом быть постоянной. Если вы заметили устойчивое покраснение кожи, обратитесь к терапевту.`,
	},
];
